import {
	BrandCode,
	IAddress,
	LimitedAddress,
	LimitedAddressInput,
	Optional,
} from '@interfaces/index';
import { ISequelizeModel, SequelizeModelKeys } from '@interfaces/model';

export enum PharmacyBrand {
	NUAA = 'NUAA',
	ANOSSADROGARIA = 'ANOSSADROGARIA',
	DROGARAIA = 'DROGARAIA',
	DROGARAIASAOP = 'DROGARAIASAOP',
	DROGAL = 'DROGAL',
	DROGARIAGLOBO = 'DROGARIAGLOBO',
	DROGASIL = 'DROGASIL',
	FARMABEM = 'FARMABEM',
	FARMAPONTE = 'FARMAPONTE',
	FLEXFARMA = 'FLEXFARMA',
	INDIANA = 'INDIANA',
	NISSEI = 'NISSEI',
	PAGUEMENOS = 'PAGUEMENOS',
	REDEPHARMA = 'REDEPHARMA',
	SANTOREMEDIO = 'SANTOREMEDIO',
	ULTRAFARMA = 'ULTRAFARMA',
	VENANCIO = 'VENANCIO',
	ARAUJO = 'ARAUJO',
	UNKNOWN = 'UNKNOWN',
}

export interface IPharmacy extends ISequelizeModel {
	label: string;
	enabled: boolean;
	brand: PharmacyBrand;
	brandCode: BrandCode;
	addressId: string;
	address: IAddress;
}

export type PharmacyPublic = Omit<IPharmacy, 'address'> & {
	address: LimitedAddress;
};
export type PharmacyCreationAttributes = Optional<
	Omit<IPharmacy, SequelizeModelKeys>,
	'address' | 'addressId'
>;

export interface IPharmacyInput {
	label: string;
	enabled: boolean;
	brand: PharmacyBrand;
	brandCode: BrandCode;
	address: LimitedAddressInput;
}
